/*
 * Schatten entfernen; stattdessen: "borders"
 */

table.resources .item {
  background: none;
  border-right: 1px solid;
}

table.resources > thead > tr > th:first-child,
table.resources > tbody > tr > th:first-child {
  border-right: 1px solid;
}

/*
 * Farbe des Haupt-Gitters dunkler machen
 */
table.resources .item,
table.resources > thead > tr > th:first-child,
table.resources > tbody > tr > th:first-child,
  /* und zusaetzlich: */
.resources.list > tbody:first-of-type > tr > td:not(.item),
.resources.list tr.item > *,
.resources.list .re-day,
.re-item {
  border-color: #bfbfbf !important;
}

/* Navigationselemente ausblenden */
table.resources a.new-ressource-button,
table.resources a.dependencyToggle {
  visibility: hidden; /* for display: none/block is toggled via JS */
}

/*
 * Logos im Hintergrund austauschen; schrittweise: 1., 2., 3.
 */
.resources .calendar-2 .eventinfo {
  /* 1. Hintergrund loeschen */
  background: transparent none;
  /* 2. Positionierung des neu eingefuegten(!) Hintergrundes */
  padding-left: 0;
  line-height: 16px;
}

.resources .calendar-2 .eventinfo:before {
  /* 3. Hintergrund wieder einfuegen */
  content: url(../img/icons/cal/icn_leave.gif);
}

/*
 * 1., 2., 3. siehe oben
 */
table.resources .ghosts .re-milestone,
.re-milestone {
  background: transparent none;
  padding-left: 8px; /* = (28px [Gesamtbreite] - 12px [Iconbreite]) / 2 */
  line-height: 16px;
}

table.resources .ghosts .re-milestone:before {
  content: url(../img/icons/icn_milestone_ghost_small.png);
}

.re-milestone:before {
  content: url(../img/icons/icn_milestone_small.png);
}

/* "CSS gefrickel" --- ist doof, aber trotzdem muessen die beiden Anweisungen rein */
table.resources .ghosts .re-milestone {
  z-index: 1000;
}

.re-milestone {
  margin-top: 3px;
}
