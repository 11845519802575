/*
 * 1. die fuers Drucken uninteressanten Inhalte ausblenden
 *
 * (nur das "div.main" bleibt sichtbar!)
 */
#inform-logo,
.head,
.leftCol,
.foot,
.tools {
  display: none;
}

/*
 * 2. allgemein gueltige "Druck-Styles"
 */

body,
thead {
  background-color: #ffffff;
  color: #000000;
}

.list {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

/* Die erste "Zeile" nach dem --- im Screen-Design schwarzen (!) --- "thead" braucht jetzt eine "border top" */
.list > tbody:first-of-type > tr > td:not(.item) {
  border-top: 1px solid #e3e3e3;
}

/* Navigationselemente ausblenden */
.expand,
.collapse,
.masterCollapse.collapse {
  background: none;
}
